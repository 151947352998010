import type { AxiosError } from "axios";

export const useCustomToast = () => {
  const toast = useToast();

  function success(description?: string) {
    return toast.add({
      title: "Sucesso!",
      description,
      color: "green",
    });
  }

  function error(description?: string) {
    return toast.add({
      title: "Erro",
      description,
      color: "red",
    });
  }

  function axiosError(errorResponse?: AxiosError | Error) {
    return toast.add({
      title: "Erro",
      description: errorResponse?.response?.data?.message || 'Ocorreu um erro inesperado',
      color: "red",
    })
  }

  return {
    success,
    error,
    axiosError
  };
};
